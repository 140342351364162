<template>
  <el-dialog
    custom-class="main-analyze-dialog common-dialog"
    :visible="visible"
    title="主页分析"
    @close="handleClose"
    :append-to-body="true"
  >
    <el-select
      size="small"
      v-model="initSelId"
      @change="handleChangeId"
      style="margin-bottom:15px;"
    >
      <el-option
        v-for="(item, index) in ids"
        :key="index"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <cus-table
      ref="cusTableRef"
      :tableParams="tableParams"
      :tableData="tableData"
      :colData="colData"
      :isInfiniteScroll="true"
      :infiniteScrollImmediate="false"
      :infiniteScrollDisabled="infiniteScrollDisabled"
      @load-more="loadMore"
      :appendData="appendData"
      :loading="appendData.loading"
    >
      <template v-slot:cusheader="{ scope }">
        <template v-if="scope.column.sortable">
          <el-tooltip
            effect="dark"
            :content="
              scope.column.order == 'ascending'
                ? '点击降序'
                : scope.column.order == 'descending'
                ? '取消排序'
                : '点击升序'
            "
            placement="top"
          >
            <span>{{ scope.column.label }}</span>
          </el-tooltip>
        </template>
      </template>
      <template v-slot:colslot="{ scope, prop }">
        <div v-if="prop == 'likes' || prop == 'comments' || prop == 'shares'">
          {{
            scope.row[prop]
              ? scope.row[prop]
              : scope.row[prop] == 0
              ? scope.row[prop]
              : "0"
          }}
        </div>
        <!-- facebook帖子 -->
        <div v-else-if="prop == 'facebook'" class="dp-flex align-item-c ">
          <el-image
            style="width: 44px; height: 44px;margin-right:20px;"
            :src="scope.row.picture"
            :preview-src-list="[scope.row.picture]"
          >
          </el-image>
          <p class="flex-1 three-row">{{ scope.row.message }}</p>
        </div>
      </template>
      <template v-slot:cusappend="{ data }">
        <p class="text-a-c" style="line-height:50px;" v-if="data.loading">
          努力加载中...
        </p>
        <p class="text-a-c" style="line-height:50px;" v-if="data.nomore">
          抱歉，没有更多了!
        </p>
      </template>
    </cus-table>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button size="small" type="primary" @click="handleConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cusTable from "@/views/components/cusTable";
import { apPosts } from "@/api/adAssets";
export default {
  components: {
    cusTable
  },
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    initData: {
      type: String
    },
    ids: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableLoading: false,
      tableParams: {
        className: "cus-table",
        border: true,
        infiniteScroll: true,
        height: "100%"
      },
      tableData: [],
      colData: [
        {
          label: "创建时间",
          prop: "created_time",
          sortable: true,
          width: "160px"
        },
        {
          label: "Facebook帖子",
          prop: "facebook",
          width: "320px"
        },
        { label: "点赞数", prop: "likes", sortable: true },
        { label: "评论数", prop: "comments", sortable: true },
        { label: "分享数", prop: "shares", sortable: true }
      ],
      initSelId: "",
      pageAfter: "",
      // 表格最后一行之后的内容-加载中-没有更多
      appendData: {
        loading: false,
        nomore: false
      },
      resCode: 0
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return (
        this.appendData.loading || this.appendData.nomore || this.resCode == 1
      );
    }
  },
  methods: {
    // 获取主页帖子
    apPosts(pageId) {
      this.appendData.loading = true;
      let params = {
        after: this.pageAfter,
        pageId
      };
      // console.log("params",params)
      apPosts(params).then(res => {
        this.appendData.loading = false;
        this.resCode = res.code;
        if (res.code == 200) {
          let arr = res.data.data ? res.data.data : [];
          this.tableData.push(...arr);
          this.pageAfter = res.data.paging ? res.data.paging.after : "";
        }
      });
    },
    loadMore() {
      this.apPosts(this.initSelId);
    },
    handleClose() {
      this.$emit("close-dialog");
      this.pageAfter = "";
      this.tableData = [];
    },
    handleConfirm() {
      this.$emit("close-dialog");
    },
    // id-change
    handleChangeId(val) {
      // console.log('handleChangeId(val)',val)
      this.apPosts(val);
    }
  },
  watch: {
    initData(val) {
      // console.log('initData',val)
      if (val) {
        this.initSelId = val;
        this.apPosts(val);
      }
    },
    pageAfter: {
      handler(val, oldval) {
        if (!val && oldval) {
          this.appendData.nomore = true;
        } else {
          this.appendData.nomore = false;
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.main-analyze-dialog {
  width: 900px;
  .el-dialog__body {
    @extend .dp-flex;
    @extend .flex-d-c;
  }
  .el-table {
    @extend .dp-flex;
    @extend .flex-d-c;
  }
  .el-table__header-wrapper {
    overflow: visible;
  }
  .el-table__body-wrapper {
    @extend .flex-1;
  }
  .el-dialog__header {
    @extend .dp-flex;
  }
  .three-row {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .el-select {
    width: 300px;
  }
}
</style>